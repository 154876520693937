.checkout-payment-inputs {
  direction: ltr !important;
  text-align: right;
}

.display-inputs-rtl {
  direction: ltr !important;
}

// Theme RTL Css

@media only screen and (min-width: 768px) {
  .theme_dir__rtl .Locate-Any-Phone {
    background-image: url("../assets/home/locate-any-phone-bg-rotate.webp");
    background-position: center;
  }
}

@media only screen and (max-width: 767px) {
  .theme_dir__rtl .Locate-Any-Phone .wid-home-banner {
    padding: 0px 6% 0px;
  }
}

// Track Page RTL Css
.theme_dir__rtl {
  .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .left-contant {
          .head-input {
            .input-suffix {
              left: 0px;
              right: auto;
            }
            .desktop-input-nd {
              border-left: unset;
              border-right: 1px solid gray;
              border-radius: unset;
            }
            .react-tel-input {
              border-radius: 0px 50px 50px 0px !important;
              input {
                padding-left: 10px !important;
                border-radius: 50px 50px 50px 50px !important;
              }
              .flag-dropdown {
                padding: 0px 5px;
                .selected-flag {
                  padding: 0px 17px 0px 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .theme_dir__rtl {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .left-contant {
            .head-input {
              .react-tel-input {
                input {
                  padding-left: 10px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.input_ltr {
  text-align: right;
  direction: ltr;
}

@media only screen and (min-width: 1200px) {
  .theme_dir__rtl {
    .bg-grad-section {
      .Locate-Any-Phone {
        background-image: url("../assets/home/locate-any-phone-bg-rotate.webp");
      }
    }
  }
}

.theme_dir__rtl {
  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .left-contant {
            .head-input {
              .input-suffix {
                right: auto;
                left: 0px;
              }

              .react-tel-input {
                input {
                  padding-left: 0px;
                  padding-right: 75px;
                  text-align: right;
                }
              }

              .desktop-input {
                .flag-dropdown {
                  .selected-flag {
                    padding: 0px 12px 0px 0px;
                    @media screen and (max-width: 775px) {
                      padding-right: 8px !important;
                    }
                  }

                  &::after {
                    left: 0px;
                    right: auto;
                  }
                }
              }

              .mobile-input {
                .flag-dropdown {
                  .selected-flag {
                    padding: 0px 17px 0px 0px;
                  }

                  &::after {
                    left: 0px;
                    right: auto;
                  }
                }
              }

              .desktop-input-nd {
                border-left: unset;
              }
            }
          }
        }
      }
    }

    .how-work-section {
      .how-work-container {
        .how-work-body {
          .how-work-desc-sections {
            .box {
              border-radius: 60px 60px 60px 60px;

              .bgboximg {
                object-fit: unset;
              }

              .how-work-contant {
                .left-contant {
                  padding-left: 0px;
                  padding-right: 40px;
                }
              }
            }
          }
        }
      }
    }

    .home-special-offer {
      .specialOffer-container {
        .special-offerbox-partition {
          .img-box {
            .backGroundImg2 {
              img {
                transform: rotateY(180deg);
              }
            }
          }
        }
      }
    }
  }

  .header-main-section {
    .header {
      .header-df {
        .header-main-logo {
          margin-left: 0px !important;
          margin-right: 50px;

          a {
            height: 100%;
            width: 100%;
            display: inline-block;
            vertical-align: top;
          }
        }

        .header-menu-nd-login {
          .header-login-btn {
            margin-right: 30px;
          }

          .header-lang-drop {
            margin-left: 0px;
            margin-right: 20px;
          }
        }
      }
    }
  }

  .pricing-main-section {
    .container {
      .home-special-offer {
        .specialOffer-container {
          .special-offerbox-partition {
            .img-box {
              .backGroundImg {
                img {
                  transform: rotateY(180deg);
                }
              }
            }
          }
        }
      }
    }
  }

  .wrap-top-modal {
    .body-dash-modal {
      .dashboard-find-number {
        .dashboard-locate {
          input {
            padding-left: 0px;
            padding-right: 75px;
            text-align: right;
          }
        }

        label {
          span {
            &:last-child {
              margin-left: 0px;
              margin-right: 1rem;
            }
          }
        }
      }

      .dashboard-message {
        label {
          span {
            &:last-child {
              margin-left: 0px;
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }

  .dashboard-main-section {
    .container {
      .dashboard-find-number {
        label {
          span {
            &:last-child {
              margin-left: 0px;
              margin-right: 1rem;
            }
          }
        }

        .dashboard-locate {
          input {
            padding-left: 0px;
            padding-right: 75px;
            text-align: right;
          }

          .input-suffix {
            right: auto;
            left: 5px;
          }

          .flag-dropdown {
            border-radius: 0px 15px 15px 0px;
          }

          .selected-flag {
            padding: 0px 30px 0px 30px;
          }
        }
      }

      .setting-find-number {
        .setting-billing-heading {
          span {
            &:last-child {
              margin-left: 0px;
              margin-right: 1rem;
            }
          }
        }
      }

      .dashboard-history {
        .dashboard-history-title {
          label {
            span {
              &:last-child {
                margin-left: 0px;
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }
  }

  .login-modal-section {
    .wrap-top-modal {
      .body-dash-modal {
        .submit-btn-modal {
          button {
            box-shadow: unset;
          }
        }

        .dashboard-find-number {
          .dashboard-locate {
            .flag-dropdown {
              border-radius: 0px 15px 15px 0px;
            }

            .selected-flag {
              padding: 0px 30px 0px 30px;
            }
          }
        }
      }
    }
  }

  .selected-flag {
    &:hover {
      background-color: transparent !important;
    }
  }

  .react-tel-input {
    .country-list {
      .flag {
        margin-right: 0px;
        margin-left: 7px;
      }
    }
  }
}

.theme_dir__rtl {
  .login-modal-section {
    .unsubscribe-modal-body {
      .submit-btn-modal {
        button {
          box-shadow: unset;
        }
      }
    }
  }
}

.theme_dir__rtl {
  .dashboard-main-section {
    .container {
      .setting-find-number {
        .billing-form {
          .input-fx {
            .f-name-input {
              select {
                background-position: center left 10px;
              }
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .left-contant {
            .head-input {
              .desktop-input {
                .flag-dropdown {
                  &:hover {
                    background: transparent !important;
                  }
                }
              }

              .mobile-input {
                .flag-dropdown {
                  &:hover {
                    background: transparent !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .react-tel-input {
    .country-list {
      max-height: 190px;
    }
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1200px) {
  .theme_dir__rtl {
    .bg-grad-section {
      .Locate-Any-Phone {
        .wid-home-banner {
          .df-first-section {
            .left-contant {
              width: 100%;
              max-width: 500px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .theme_dir__rtl {
    .bg-grad-section {
      .Locate-Any-Phone {
        .wid-home-banner {
          padding: 20% 4% 15%;

          .df-first-section {
            .left-contant {
              .head-input {
                .react-tel-input {
                  width: 90px;
                  flex: 0 0 90px;
                  input {
                    width: 120px;
                    border-radius: 0 50px 50px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .theme_dir__rtl {
    .bg-grad-section {
      .how-work-section {
        .how-work-container {
          .how-work-body {
            .how-work-desc-sections {
              .box {
                border-radius: 30px 30px 30px 30px;

                .bgboximg {
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }

    .header-main-section {
      .header {
        .header-df {
          .header-menu-nd-login {
            .responsive-header-btn {
              svg {
                font-size: 35px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .header-main-section {
    .header {
      .header-df {
        .header-main-logo {
          margin-right: 15px;
        }
      }
    }
  }

  .theme_dir__rtl {
    .header-main-section {
      .header {
        .header-df {
          .header-main-logo {
            margin-right: 15px;
          }
        }
      }
    }
  }
}

// Track Page Css

.theme_dir__rtl {
  .header-main-section {
    .header {
      .header-df {
        .header-menu-nd-login {
          margin-right: 50px !important;
          margin-left: 0px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1099px) {
  .theme_dir__rtl {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .right-contant {
            .banner-img {
              img {
                width: 500px;
              }
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  button.ant-modal-close {
    span.ant-modal-close-x {
      justify-content: flex-start;
    }
  }
}

// New Extrenal Css
.theme_dir__rtl.login_btn__wrapper {
  button.ant-modal-close {
    left: 5% !important;
    right: auto !important;
    width: auto !important;
  }
}

.theme_dir__rtl.location_modal {
  button.ant-modal-close {
    left: 5% !important;
    right: auto !important;
    width: auto !important;
  }
}

.theme_dir__rtl.ant-dropdown {
  ul.ant-dropdown-menu {
    li.ant-dropdown-menu-item {
      span.ant-dropdown-menu-title-content {
        a {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .ant-modal-body {
    .login-modal-section {
      .wrap-top-modal {
        .google-button-login {
          button.login-with-google-btn {
            background-position: calc(100% - 15px) center;
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .left-contant {
            .head-input {
              .desktop-input {
                border-radius: 0px 50px 50px 50px;
                width: 110px;
              }
              .react-tel-input {
                input {
                  border-radius: 0px 50px 50px 50px !important;
                  padding-right: 45px !important;
                  @media screen and (max-width: 775px) {
                    padding-right: 40px !important;
                  }
                  @media screen and (max-width: 480px) {
                    padding-right: 50px !important;
                  }
                }
              }
              .big_city_dial {
                width: 120px;
                .phone-input-field {
                  padding-right: 48px !important;
                  padding-left: 6px !important;
                }
                .flag-dropdown {
                  .selected-flag {
                    padding: 0px 11px 0px 0px;
                  }
                }
              }
              .three_city_dial {
                .phone-input-field {
                  padding-right: 48px !important;
                  padding-left: 5px !important;
                }
                .flag-dropdown {
                  .selected-flag {
                    padding: 0px 11px 0px 0px;
                  }
                }
              }
              .medium_city_dial {
                @media screen and (max-width: 767px) {
                  width: 85px !important;
                  flex: 0 0 85px !important;
                }
                .phone-input-field {
                  padding-right: 55px !important;
                }
                .flag-dropdown {
                  .selected-flag {
                    padding: 0px 13px 0px 0px;
                  }
                }
              }
              .small_city_dial {
                @media screen and (max-width: 767px) {
                  width: 75px !important;
                  flex: 0 0 75px !important;
                }
                width: 90px;
                .phone-input-field {
                  padding-right: 50px !important;
                }
                .flag-dropdown {
                  .selected-flag {
                    padding: 0px 13px 0px 0px;
                  }
                }
              }
              .desktop-input-nd {
                border-right: 1px solid gray;
                border-radius: unset;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .theme_dir__rtl {
    .bg-grad-section {
      .Locate-Any-Phone {
        .wid-home-banner {
          .df-first-section {
            .left-contant {
              .head-input {
                .react-tel-input {
                  border-radius: 0px 50px 50px 0px;
                  width: 95px;
                  flex: 0 0 95px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// RTL Background Css

@media only screen and (max-width: 1299px) {
  .theme_dir__rtl {
    .bg-grad-section {
      .how-work-section {
        .how-work-container {
          .how-work-body {
            .how-work-desc-sections {
              .box {
                .how-work-contant {
                  .left-contant {
                    width: 100% !important;
                    max-width: 380px !important;
                    margin: 0px auto !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 875px) {
  .theme_dir__rtl {
    .bg-grad-section {
      .how-work-section {
        .how-work-container {
          .how-work-body {
            .how-work-desc-sections {
              .box {
                .how-work-contant {
                  .left-contant {
                    width: 100% !important;
                    max-width: 320px !important;
                    margin: 0px auto !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .theme_dir__rtl {
    .bg-grad-section {
      .how-work-section {
        .how-work-container {
          .how-work-body {
            .how-work-desc-sections {
              .box {
                .how-work-contant {
                  .left-contant {
                    position: relative;
                    z-index: 1;
                    min-height: inherit !important;
                    padding-top: 80px !important;
                    &:before {
                      content: "";
                      position: absolute;
                      z-index: -1;
                      left: -24px;
                      right: -24px;
                      top: 150px;
                      bottom: 0px;
                      display: block;
                      background-color: #ecedef;
                      border-radius: 25px 25px 35px 35px;
                    }
                  }
                }
                .bgboximg {
                  object-fit: unset !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .theme_dir__rtl {
    .bg-grad-section {
      .Locate-Any-Phone {
        .wid-home-banner {
          .df-first-section {
            .right-contant {
              .banner-img {
                .location-banner-img-desc {
                  left: 13%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .theme_dir__rtl .Locate-Any-Phone {
    background-image: url("../assets/home/locate-any-phone-bg-rotate.webp");
    background-position: center;
  }
}

@media only screen and (max-width: 767px) {
  .theme_dir__rtl .Locate-Any-Phone .wid-home-banner {
    padding: 0px 6% 0px;
  }
}

// Track Page RTL Css

.theme_dir__rtl {
  .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .left-contant {
          .head-input {
            .input-suffix {
              left: 0px;
              right: auto;
            }
            .desktop-input-nd {
              border-left: unset;
              border-right: 1px solid gray;
              border-radius: unset;
            }
            .react-tel-input {
              border-radius: 0px 50px 50px 0px !important;
              input {
                padding-left: 10px !important;
                border-radius: 50px 50px 50px 50px !important;
              }
              .flag-dropdown {
                padding: 0px 5px;
                .selected-flag {
                  padding: 0px 12px 0px 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .theme_dir__rtl {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .left-contant {
            .head-input {
              .react-tel-input {
                input {
                  padding-left: 10px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .theme_dir__rtl {
    .header-main-section {
      .header {
        .header-df {
          .header-menu-nd-login {
            margin-right: 20px !important;
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .right-contant {
            .banner-img {
              .location-banner-img-text {
                top: 52%;
              }
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .right-contant {
          .banner-img {
            .location-banner-img-text {
              width: auto;
              top: 52%;
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .dashboard-main-section {
    .container {
      .dashboard-find-number {
        .dashboard-locate {
          input.desktop-input-nd {
            padding-right: 60px;
            padding-left: 0px;
            left: 0;
            right: auto;
          }

          .flag-dropdown {
            left: auto;
            right: 0px;
          }

          input {
            padding-right: 55px !important;
          }

          .selected-flag {
            padding: 0 78px 0 20px !important;
          }
        }
      }
    }

    .dashboard-find-number {
      .react-tel-input {
        .selected-flag {
          .arrow {
            left: -20px;
          }

          .flag {
            right: 20px;
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .login-modal-section {
    .wrap-top-modal {
      .body-dash-modal {
        .dashboard-find-number {
          .dashboard-locate {
            .flag-dropdown {
              z-index: 9999;
            }

            input {
              padding-left: 80px;
              padding-right: 10px;
              left: 0;
              right: auto;
              border-radius: 15px 0px 0px 15px;
            }

            .dsh_locating_popup {
              input {
                border-radius: 15px 15px 15px 15px;
                padding-right: 25px;
              }
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .login-modal-section {
    .wrap-top-modal {
      .body-dash-modal {
        .dashboard-message {
          .erroe_char-length {
            .max_char_length {
              text-align: left;
            }
          }
        }
      }
    }
  }

  .header-lang-dropdown {
    margin-left: 10px;
    margin-right: auto;
  }
}

.theme_dir__rtl {
  .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .left-contant {
          .head-input {
            .desktop-input-nd {
              border-radius: 50px 0px 0px 50px !important;
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .left-contant {
          .head-input {
            .react-tel-input {
              .flag-dropdown {
                .selected-flag {
                  .flag {
                    .arrow {
                      right: 20px;
                      left: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl.dsh_location_modal_popup {
  .login-modal-section {
    .wrap-top-modal {
      .body-dash-modal {
        .dashboard-find-number {
          .dashboard-locate {
            .form-control {
              padding-right: 48px;
            }
            .flag-dropdown {
              max-width: 21%;
              width: 100%;
              .selected-flag {
                padding: 0px 20px 0px 20px;
                width: 100%;
                .flag {
                  .arrow {
                    right: 20px;
                    left: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl.location_modal {
  button.ant-modal-close {
    &:hover {
      background-color: transparent;
    }
  }
}

.theme_dir__rtl {
  .footer {
    .footer-container {
      .footer-section {
        .footer-inner-container {
          .footer-bottom-contant {
            .footer-bottom-flex {
              .tracelo-footer {
                margin-right: 0px;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}